import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    error: null,
    response: null,
}

export const GetNotificationList = createAsyncThunk(
    "GetNotificationList",
    async (body) => {
        try {
            const response = await instance.get('crm/notify/fetch-notify')
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const ReadNotification = createAsyncThunk(
    "ReadNotification",
    async (id) => {
        try {
            const response = await instance.get(`crm/notify/read-notify?id=${id}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)


export const NotificationSlice = createSlice({
    name: "NotificationSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetNotificationList.pending, (state) => {
            state.loading = true;
            state.data = null;
        });
        builder.addCase(GetNotificationList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(GetNotificationList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(ReadNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(ReadNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(ReadNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    }
})

export default NotificationSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    gcData: null,
    maData: null,
    omData: null,
    loading: false,
    error: null,
    marketResponse: null,
}

export const GetGoogleCampaignList = createAsyncThunk(
    "GetGoogleCampaignList",
    async (body) => {
        try {
            const response = await instance.get(`crm/marketing/fetch-google-campaign?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const GetMetaAdsList = createAsyncThunk(
    "GetMetaAdsList",
    async (body) => {
        try {
            const response = await instance.get(`crm/marketing/fetch-meta-ads?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const GetOfflineMarketingList = createAsyncThunk(
    "GetOfflineMarketingList",
    async (body) => {
        try {
            const response = await instance.get(`crm/marketing/fetch-offline-market?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)


export const CreateMarketing = createAsyncThunk(
    "CreateMarketing",
    async (body) => {
        try {
            const response = await instance.post('crm/marketing/add-marketing', body)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const AddExcelMarketFile = createAsyncThunk(
    "AddExcelMarketFile",
    async (body) => {
        const formData = new FormData();
        formData.append("excelfile", body);
        try {
            const res = await instance.post("crm/marketing/upload-market-excel", formData)
            return res.data
        } catch (error) {
            return error
        }

    }
)

export const UpdateMarketing = createAsyncThunk(
    "UpdateMarketing",
    async (body) => {
        try {
            const response = await instance.post(`crm/marketing/update-marketing?id=${body.id}`, body.data)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)




export const MarketingSlice = createSlice({
    name: "MarketingSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetGoogleCampaignList.pending, (state) => {
            state.loading = true;
            state.marketResponse = null;
        });
        builder.addCase(GetGoogleCampaignList.fulfilled, (state, action) => {
            state.loading = false;
            state.gcData = action.payload;
        });
        builder.addCase(GetGoogleCampaignList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
        
        builder.addCase(GetMetaAdsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetMetaAdsList.fulfilled, (state, action) => {
            state.loading = false;
            state.maData = action.payload;
        });
        builder.addCase(GetMetaAdsList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(GetOfflineMarketingList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetOfflineMarketingList.fulfilled, (state, action) => {
            state.loading = false;
            state.omData = action.payload;
        });
        builder.addCase(GetOfflineMarketingList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(CreateMarketing.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(CreateMarketing.fulfilled, (state, action) => {
            state.loading = false;
            state.marketResponse = action.payload;
        });
        builder.addCase(CreateMarketing.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(AddExcelMarketFile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(AddExcelMarketFile.fulfilled, (state, action) => {
            state.loading = false;
            state.marketResponse = action.payload;
        });
        builder.addCase(AddExcelMarketFile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(UpdateMarketing.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(UpdateMarketing.fulfilled, (state, action) => {
            state.loading = false;
            state.marketResponse = action.payload;
        });
        builder.addCase(UpdateMarketing.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    }
})

export default MarketingSlice.reducer;
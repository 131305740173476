import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
}

export const getInquiryFraction = createAsyncThunk(
    "getInquiryFraction",
    async (body) => {
        try {
            const response = await instance.get(`/fraction/inquiry/fetch-all?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

// export const sendInquiryDescription = createAsyncThunk(
//     "sendInquiryDescription",
//     async (body) => {
//         try {
//             const response = await instance.post(`/remark/inquiry/update-inquiry?id=${body.id}`,body.data)
//             return response.data;
//         } catch (error) {
//             throw error
//         }
//     }
// )

export const InquiryFractionSlice = createSlice({
    name: "InquiryFractionSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInquiryFraction.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getInquiryFraction.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getInquiryFraction.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        // builder.addCase(sendInquiryDescription.pending, (state, action) => {
        //     state.response = null;
        //     state.loading = true;
        // });
        // builder.addCase(sendInquiryDescription.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.response = action.payload;
        //     state.status = action.payload.status;
        // });
        // builder.addCase(sendInquiryDescription.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload;
        // })    
    }
})

export default InquiryFractionSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    loading: false,
    data: null, // don't do null any condition
    roleList: null, // don't do null any condition
    error: null,
    response: null,
    departmentData: null,
}

export const fetchRole = createAsyncThunk(
    "fetchRole",
    async () => {
        try {
            const res = await instance.get('/crm/role/fetch-all-role')
            return res.data
        } catch (error) {
            return error;
        }
    }
) 

export const fetchDepartment = createAsyncThunk(
    "fetchDepartment",
    async () => {
        try {
            const res = await instance.get('/crm/role/fetch-department')
            return res.data
        } catch (error) {
            return error;
        }
    }
) 
export const approvedRole = createAsyncThunk(
    "approvedRole",
    async () => {
        try {
            const res = await instance.get('/crm/role/approved-role')
            return res.data
        } catch (error) {
            return error;
        }
    }
) 

export const updateRole = createAsyncThunk(
    "updateRole",
    async (body,{rejectWithValue}) => {
        try {
            const res = await instance.post(`/crm/role/update-role?id=${body.r_id}`,body.data)
            return res.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
) 

export const createRole = createAsyncThunk(
    "createRole",
    async (body,{rejectWithValue}) => {
        try {
            const res = await instance.post('/crm/role/create-role',body)
            return res.data
        } catch (error) {
            return rejectWithValue(error);
        }
    }
) 

export const RoleSlice = createSlice({
    name : 'RoleSlice',
    initialState,
    reducer : {},
    extraReducers : 
        (builder) => {
            
            builder.addCase(fetchRole.pending, (state, action) => {
                state.loading = false
                state.data = null
                state.response = null;
            })
            builder.addCase(fetchRole.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload
            })
            builder.addCase(fetchRole.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.data
            })
            
            builder.addCase(fetchDepartment.pending, (state, action) => {
                state.loading = false
            })
            builder.addCase(fetchDepartment.fulfilled, (state, action) => {
                state.loading = false
                state.departmentData = action.payload
            })
            builder.addCase(fetchDepartment.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.data
            })

            builder.addCase(approvedRole.pending, (state, action) => {
                state.loading = false
                state.roleList = null
                state.response = null;
            })
            builder.addCase(approvedRole.fulfilled, (state, action) => {
                state.loading = false
                state.roleList = action.payload
            })
            builder.addCase(approvedRole.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.data
            })

            builder.addCase(updateRole.pending, (state, action) => {
                state.loading = false
                state.response = null;
            })
            builder.addCase(updateRole.fulfilled, (state, action) => {
                state.loading = false
                state.response = action.payload
            })
            builder.addCase(updateRole.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.data
            })

            builder.addCase(createRole.pending, (state, action) => {
                state.loading = false
                state.response = null;
            })
            builder.addCase(createRole.fulfilled, (state, action) => {
                state.loading = false
                state.response = action.payload
            })
            builder.addCase(createRole.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload?.data
            })

        }
})


export default RoleSlice.reducer;
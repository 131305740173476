import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
    singleEmployer: null
}

export const getCandidateProfile = createAsyncThunk(
    "getCandidateProfile",
    async (id) => {
        try {
            const response = await instance.get(`/remark/candidate/fetch-profile?id=${id}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateCandidateProfile = createAsyncThunk(
    "updateCandidateProfile",
    async (body) => {
        try {
            const response = await instance.post(`/remark/candidate/update-candidate?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const CandidateSlice = createSlice({
    name: "CandidateSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCandidateProfile.pending, (state, action) => {
            state.loading = true;
            state.response = null;
            state.data = null;
        });
        builder.addCase(getCandidateProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getCandidateProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(updateCandidateProfile.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(updateCandidateProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateCandidateProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
})

export default CandidateSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"
import axios from "axios";

const initialState = {
    data: null,
    loading: false,
    error: null,
    singlePro: null,
    respStatus: null,
    selectList: null,
    selectProperty: null,
}

export const fetchProperties = createAsyncThunk(
    "fetchProperties",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/property/fetch-all-property?search=${body.search}&page=${body.page}&filterCategory=${body.filterCategory}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const fetchPropertySelect = createAsyncThunk(
    "fetchPropertySelect",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get('/fraction/property/fetch-select-property');
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const fetchSingleProperty = createAsyncThunk(
    "fetchSingleProperty",
    async (id, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/property/fetch-single-property?id=${id}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const insertProperty = createAsyncThunk(
    "insertProperty",
    async (body, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            
            // PROPERTIES INPUT
            formData.append("p_name", body.p_name)
            formData.append("p_value", body.p_value)
            formData.append("p_builder_id", body.p_builder_id)
            formData.append("p_size", body.p_size)
            formData.append("p_per_sq_cost", body.p_per_sq_cost)
            formData.append("p_is_furnished", body.p_is_furnished)
            formData.append("p_amenities", body.p_amenities)
            formData.append("p_location", body.p_location)
            formData.append("p_area", body.p_area)
            formData.append("p_city", body.p_city)
            formData.append("p_state", body.p_state)
            formData.append("p_tickets_no", body.p_tickets_no)
            formData.append("p_details", body.p_details)
            formData.append("p_lat_long", JSON.stringify(body.p_lat_long))
            
            // CATEGORY INPUTS
            formData.append("cat_category", body.cat_category)
            formData.append("cat_sub_category", body.cat_sub_category)
            formData.append("cat_sub_sub_category", body.cat_sub_sub_category)
            
            // LLP INPUTS
            formData.append("p_is_llp", body.p_is_llp)
            formData.append("llp_id", body.llp_id)


            const response = await instance.post('/fraction/property/insert-property', formData);
            return response.data;

        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const insertMediaProperty = createAsyncThunk(
    "insertMediaProperty",
    async (body, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append("p_id", body.p_id)
            if (typeof body?.p_media == 'object') {
                for (let i = 0; i < body.p_media.length; i++) {
                    // 'images' name of the formData values must match the action method param on your controller
                    formData.append('p_media[]', body.p_media[i]);
                }
            }

            const response = await axios.post('https://admin.fractionrealty.in/property-media/add-media', formData);
            return response.data;

        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const insertPdfProperty = createAsyncThunk(
    "insertPdfProperty",
    async (body, { rejectWithValue }) => {
        try {
            console.log(852,body)
            const formData = new FormData();
            formData.append("p_id", body.p_id)
            formData.append('p_pdf', body.p_pdf[0]);

            const response = await axios.post('https://admin.fractionrealty.in/property-media/add-pdf', formData);
            return response.data;

        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const insertPropertyDocument = createAsyncThunk(
    "insertPropertyDocument",
    async (body, { rejectWithValue }) => {
        try {
            console.log(852,body)
            const formData = new FormData();
            formData.append("p_id", body.p_id)
            formData.append("document_name", body.document_name)
            formData.append('document', body.document[0]);

            const response = await axios.post('https://admin.fractionrealty.in/property-media/add-document', formData);
            return response.data;

        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const updateProperty = createAsyncThunk(
    "updateProperty",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post(`/fraction/property/update-property?id=${body.id}`, body.data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const updatePropertyCurrentValue = createAsyncThunk(
    "updatePropertyCurrentValue",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/property/update-property-current-value?id=${body.id}&p_current_value=${body.p_current_value}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const beforeInsertProperty = createAsyncThunk(
    "beforeInsertProperty",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get('/fraction/property/before-insert-property');
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)


export const PropertySlice = createSlice({
    name: 'PropertySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProperties.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(fetchProperties.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchProperties.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(fetchSingleProperty.pending, (state, action) => {
            state.loading = true;
            state.singlePro = null;
            state.respStatus = null;
        })
        builder.addCase(fetchSingleProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.singlePro = action.payload;
        })
        builder.addCase(fetchSingleProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(insertProperty.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(insertProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(insertProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(insertMediaProperty.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(insertMediaProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(insertMediaProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(insertPropertyDocument.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(insertPropertyDocument.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(insertPropertyDocument.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(insertPdfProperty.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(insertPdfProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(insertPdfProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateProperty.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(updateProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(updateProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updatePropertyCurrentValue.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(updatePropertyCurrentValue.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(updatePropertyCurrentValue.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(beforeInsertProperty.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(beforeInsertProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.selectList = action.payload;
        })
        builder.addCase(beforeInsertProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
        
        builder.addCase(fetchPropertySelect.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchPropertySelect.fulfilled, (state, action) => {
            state.loading = false;
            state.selectProperty = action.payload;
        })
        builder.addCase(fetchPropertySelect.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    },

})

export default PropertySlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    mapActivity:null,
}

export const GetRecentActivity = createAsyncThunk(
    "GetRecentActivity",
    async (body) => {
        try {
            const response = await instance.get(`/crm/recent-activity/fetch-recent-activity?e_id=${body?.ra_emp_id}&date=${body?.ra_date}`)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const AddRecentActivity = createAsyncThunk(
    "AddRecentActivity",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post("/crm/recent-activity/add-recent-activity", body)
            return response.data; 

        } catch (error) {
            return rejectWithValue(error?.response);
        }
    }
)

export const RecentlyActivitySlice = createSlice({
    name: "RecentlyActivitySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetRecentActivity.pending, (state) => {
            state.loading = true;
            state.mapActivity = null;
        });
        builder.addCase(GetRecentActivity.fulfilled, (state, action) => {
            state.loading = false;
            state.mapActivity = action.payload;
        });
        builder.addCase(GetRecentActivity.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(AddRecentActivity.pending, (state) => {
            state.loading = true;
            state.data = null;
        });
        builder.addCase(AddRecentActivity.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(AddRecentActivity.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

    }
})

export default RecentlyActivitySlice.reducer;
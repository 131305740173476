import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
}

export const getCompanykycList = createAsyncThunk(
    "getCompanykycList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/company-kyc/fetch-all?page=${body.page}&search=${body.search}&filter=${body.filter}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateCompanyKyc = createAsyncThunk(
    "updateCompanyKyc",
    async (body) => {
        try {
            const response = await instance.post(`/remark/company-kyc/update-single-ck?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const MailSendToResubmissionDoc = createAsyncThunk(
    "MailSendToResubmissionDoc",
    async (gmailId) => {
        try {
            const response = await instance.get(`/remark/email/doc-resubmission-mail?gmailId=${gmailId}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const CompanyKycSlice = createSlice({
    name: "CompanyKycSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCompanykycList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getCompanykycList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getCompanykycList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
      
        builder.addCase(updateCompanyKyc.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateCompanyKyc.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateCompanyKyc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(MailSendToResubmissionDoc.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(MailSendToResubmissionDoc.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(MailSendToResubmissionDoc.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    
    }
})

export default CompanyKycSlice.reducer;
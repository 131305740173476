import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    error: null,
    respStatus : null,
}

export const fetchRequest = createAsyncThunk(
    "fetchRequest",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/request/fetch-all-request?search=${body.search}&page=${body.page}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const InsertRequest = createAsyncThunk(
    "InsertRequest",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post('/fraction/request/insert-request', body);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)


export const RequestSlice = createSlice({
    name: 'RequestSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRequest.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(fetchRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
   
        builder.addCase(InsertRequest.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(InsertRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(InsertRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
    },

})

export default RequestSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    interviewResponse: null,
}

export const GetInterviewList = createAsyncThunk(
    "GetInterviewList",
    async (body) => {
        try {
            const response = await instance.get(`crm/interview/fetch-all?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const CreateInterview = createAsyncThunk(
    "CreateInterview",
    async (body) => {
        try {
            const formData = new FormData();
            formData.append("iv_name", body.iv_name);
            formData.append("iv_mobile", body.iv_mobile);
            formData.append("iv_profile", body.iv_profile);
            formData.append("iv_sch_date", body.iv_sch_date);
            formData.append("iv_sch_time", body.iv_sch_time);
            formData.append("iv_ref", body.iv_ref);
            formData.append("iv_status", body.iv_status);
            formData.append("iv_last_ctc", body.iv_last_ctc);
            formData.append("iv_expectation", body.iv_expectation);
            formData.append("iv_city", body.iv_city);
            formData.append("iv_state", body.iv_state);
            formData.append("iv_resume", body.iv_resume[0]);

            const res = await instance.post("/crm/interview/add-resume", formData)
            return res.data
        } catch (error) {
            throw error
        }

    }
)

export const updateInterview = createAsyncThunk(
    "updateInterview",
    async (body) => {
        try {
            const res = await instance.post(`/crm/interview/update-resume?id=${body.id}`, body.data)
            return res.data
        } catch (error) {
            throw error
        }

    }
)

export const InterviewSlice = createSlice({
    name: "InterviewSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetInterviewList.pending, (state) => {
            state.loading = true;
            state.interviewResponse = null;
            state.data = null;
        });
        builder.addCase(GetInterviewList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(GetInterviewList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(CreateInterview.pending, (state) => {
            state.loading = true;
            state.interviewResponse = null;
        });
        builder.addCase(CreateInterview.fulfilled, (state, action) => {
            state.loading = false;
            state.interviewResponse = action.payload;
        });
        builder.addCase(CreateInterview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateInterview.pending, (state) => {
            state.loading = true;
            state.interviewResponse = null;
        });
        builder.addCase(updateInterview.fulfilled, (state, action) => {
            state.loading = false;
            state.interviewResponse = action.payload;
        });
        builder.addCase(updateInterview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
    }
})

export default InterviewSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    expenseList: null,
    singleExpense:null
}

export const GetExpensesList = createAsyncThunk(
    "GetExpensesList",
    async (body) => {
        try {
            const response = await instance.get(`/crm/expenses/fetch-expenses?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const CreateExpense = createAsyncThunk(
    "CreateExpense",
    async (body) => {
        const formData = new FormData();
        formData.append("ex_amount", body.ex_amount);
        formData.append("ex_category", body.ex_category);
        formData.append("ex_date", body.ex_date);
        formData.append("ex_description", body.ex_description);
        formData.append("ex_payment_by", body.ex_payment_by);
        formData.append("ex_payment_mode", body.ex_payment_mode);
        formData.append("ex_vendor", body.ex_vendor);
        formData.append("ex_receipt", body.ex_receipt[0]);
        try {
            const response = await instance.post("/crm/expenses/create-expense", formData);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const getSingleExpense = createAsyncThunk(
    "getSingleExpense",
    async (id) => {
        try {
            const response = await instance.get(`/crm/expenses/single-expense?id=${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const UpdateExpense = createAsyncThunk(
    "UpdateExpense",
    async (body) => {
        const formData = new FormData();
        formData.append("ex_amount", body.data.ex_amount);
        formData.append("ex_category", body.data.ex_category);
        formData.append("ex_date", body.data.ex_date);
        formData.append("ex_description", body.data.ex_description);
        formData.append("ex_payment_by", body.data.ex_payment_by);
        formData.append("ex_payment_mode", body.data.ex_payment_mode);
        formData.append("ex_vendor", body.data.ex_vendor);
        formData.append("ex_receipt", body.data.ex_receipt[0]);
        try {
            const response = await instance.post(`/crm/expenses/update-expense?id=${body?.id}`, formData)
           return response.data
        } catch (error) {
            throw error;
        }
    }
)

export const removeExpense = createAsyncThunk(
    "removeExpense", 
    async (id) => {
        try {
            const response = await instance.get(`/crm/expenses/remove-expense?id=${id}`);
            return response.data
        } catch (error) {
            
        }
    }
)
export const ExpenseSlice = createSlice({
    name: "ExpenseSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetExpensesList.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.singleExpense = null;
        });
        builder.addCase(GetExpensesList.fulfilled, (state, action) => {
            state.loading = false;
            state.expenseList = action.payload;
        });
        builder.addCase(GetExpensesList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(getSingleExpense.pending, (state) => {
            state.loading = true;
            state.data = null
        })
        builder.addCase(getSingleExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.singleExpense = action.payload;
        });
        builder.addCase(getSingleExpense.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(CreateExpense.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(CreateExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(CreateExpense.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(UpdateExpense.pending, (state) => {
            state.loading = true;
            state.data = null
        })
        builder.addCase(UpdateExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(UpdateExpense.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(removeExpense.pending, (state) => {
            state.loading = true;
            state.data = null
        })
        builder.addCase(removeExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(removeExpense.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

    }
})

export default ExpenseSlice.reducer;
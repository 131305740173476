import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    data: null,
    error: null,
}

export const GetDashboardFrac = createAsyncThunk(
    "GetDashboardFrac",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get("/fraction/dashboard/fetch-analytics");

            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response)
        }
    })


export const AnalyticFracSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetDashboardFrac.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetDashboardFrac.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(GetDashboardFrac.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        })
    }
})

export default AnalyticFracSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    ticketResponse: null,
    singleTicket: null
}

export const GetTicketList = createAsyncThunk(
    "GetTicketList",
    async (body) => {
        try {
            const response = await instance.get(`crm/ticket/fetch-all?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const InviteMember = createAsyncThunk(
    "InviteMember",
    async (body) => {
        try {
            const response = await instance.get(`crm/ticket/invite-member?id=${body.id}&inviteId=${body.inviteId}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const GetSignleTicket = createAsyncThunk(
    "GetSignleTicket",
    async (id) => {
        try {
            const response = await instance.get(`crm/ticket/single-ticket?id=${id}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const RemoveTicket = createAsyncThunk(
    "RemoveTicket",
    async (id) => {
        try {
            const response = await instance.get(`crm/ticket/remove-ticket?id=${id}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const RemoveTicketMember = createAsyncThunk(
    "RemoveTicketMember",
    async (body) => {
        try {
            const response = await instance.post(`crm/ticket/remove-ticket-member?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const CreateTicket = createAsyncThunk(
    "CreateTicket",
    async (body) => {
        try {
            const response = await instance.post('crm/ticket/create-ticket', body)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const SendCommentTicket = createAsyncThunk(
    "SendCommentTicket",
    async (body) => {
        try {
            const response = await instance.post(`crm/ticket/send-comment?id=${body.id}`, body.data)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const SendImageInComment = createAsyncThunk(
    "SendImageInComment",
    async (body) => {
        try {
            const formData = new FormData();
            formData.append("tc_image", body.tc_image[0]);
            formData.append("tc_comment_status", body.tc_comment_status);
            const response = await instance.post(`crm/ticket/send-image-comment?id=${body.id}`, formData)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const UpdateTicket = createAsyncThunk(
    "UpdateTicket",
    async (body) => {
        try {
            const response = await instance.post(`crm/ticket/update-ticket?id=${body.id}`, body.data)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)



export const TicketSlice = createSlice({
    name: "TicketSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetTicketList.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;

        });
        builder.addCase(GetTicketList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(GetTicketList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(CreateTicket.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(CreateTicket.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(CreateTicket.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(SendCommentTicket.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(SendCommentTicket.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(SendCommentTicket.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(SendImageInComment.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(SendImageInComment.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(SendImageInComment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(RemoveTicket.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(RemoveTicket.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(RemoveTicket.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(RemoveTicketMember.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(RemoveTicketMember.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(RemoveTicketMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(GetSignleTicket.pending, (state) => {
            state.loading = true;
            state.singleTicket = null;
        });
        builder.addCase(GetSignleTicket.fulfilled, (state, action) => {
            state.loading = false;
            state.singleTicket = action.payload;
        });
        builder.addCase(GetSignleTicket.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(InviteMember.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(InviteMember.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(InviteMember.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(UpdateTicket.pending, (state) => {
            state.loading = true;
            state.ticketResponse = null;
        });
        builder.addCase(UpdateTicket.fulfilled, (state, action) => {
            state.loading = false;
            state.ticketResponse = action.payload;
        });
        builder.addCase(UpdateTicket.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    }
})

export default TicketSlice.reducer;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    data: null,
    error: null,
    tcList : null,
}

export const GetDashboard = createAsyncThunk(
    "GetDashboard",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get("/crm/dashboard/analytics");

            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response)
        }
    })

export const sortTelecallers = createAsyncThunk(
    'sortTelecallers',
    async () => {
        try {
            const response = await instance.get('/crm/leads/top-list-telecaller');
            return response?.data
        }
        catch (error) {
            return error
        }
    }
)

export const DashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetDashboard.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(GetDashboard.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(GetDashboard.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        })

        builder.addCase(sortTelecallers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(sortTelecallers.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.tcList = action.payload;
        });
        builder.addCase(sortTelecallers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.data;
        })
    }

})

export default DashboardSlice.reducer;
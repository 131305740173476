import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    eventData: null,
    error: null,
    response: null,
}

export const GetEventData = createAsyncThunk(
    "GetEventData",
    async () => {
        try {
            const response = await instance.get("/crm/events/fetch-events");
            return response.data;
        } catch (error) {
            throw error;
        }
    })

export const CreateEvent = createAsyncThunk(
    "CreateEvent",
    async (body) => {
        try {
            const response = await instance.post("/crm/events/create-events", body);
            return response?.data
        } catch (error) {
            return error
        }
    }
)

export const UpdateEvent = createAsyncThunk(
    "UpdateEvent",
    async (body) => {
        try {
            const response = await instance.post(`/crm/events/update-event?id=${body.id}`, body.evData);
            return response?.data
        } catch (error) {
            return error
        }
    }
)
export const RemoveEvent = createAsyncThunk(
    "RemoveEvent",
    async (id) => {
        try {
            const response = await instance.get(`/crm/events/remove-event?id=${id}`);
            return response?.data
        } catch (error) {
            return error
        }
    }
)

export const EventSlice = createSlice({
    name: "EventSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(GetEventData.pending, (state) => {
            state.response = null;
            state.loading = true;
        });
        builder.addCase(GetEventData.fulfilled, (state, action) => {
            state.loading = false;
            state.eventData = action.payload;
        });
        builder.addCase(GetEventData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(CreateEvent.pending, (state) => {
            state.response = null;
            state.loading = true;
        });
        builder.addCase(CreateEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(CreateEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(UpdateEvent.pending, (state) => {
            state.response = null;
            state.loading = true;
        });
        builder.addCase(UpdateEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })

        builder.addCase(RemoveEvent.pending, (state) => {
            state.response = null;
            state.loading = true;
        });
        builder.addCase(RemoveEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(RemoveEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        })
    }

})

export default EventSlice.reducer;
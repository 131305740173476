import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
}

export const getInquiryList = createAsyncThunk(
    "getInquiryList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/inquiry/inquiry-list?page=${body.page}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)
export const sendInquiryDescription = createAsyncThunk(
    "sendInquiryDescription",
    async (body) => {
        try {
            const response = await instance.post(`/remark/inquiry/update-inquiry?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const InquirySlice = createSlice({
    name: "InquirySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInquiryList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getInquiryList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getInquiryList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        
        builder.addCase(sendInquiryDescription.pending, (state, action) => {
            state.response = null;
            state.loading = true;
        });
        builder.addCase(sendInquiryDescription.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.status = action.payload.status;
        });
        builder.addCase(sendInquiryDescription.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })    
    }
})

export default InquirySlice.reducer;
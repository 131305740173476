import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
}



export const getCompanyList = createAsyncThunk(
    "getCompanyList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/company/fetch-all?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateCompanyDetails = createAsyncThunk(
    "updateCompanyDetails",
    async (body) => {
        try {
            const response = await instance.post(`/remark/company/update-company?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const CompanySlice = createSlice({
    name: "CompanySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCompanyList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getCompanyList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getCompanyList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
      
        builder.addCase(updateCompanyDetails.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateCompanyDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateCompanyDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    
    }
})

export default CompanySlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    essentialResponse: null,
}

export const GetEssentialList = createAsyncThunk(
    "GetEssentialList",
    async (body) => {
        try {
            const response = await instance.get(`crm/essential/fetch-all?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const CreateEssential = createAsyncThunk(
    "CreateEssential",
    async (body) => {
        try {
            const formData = new FormData();
            formData.append("ess_name", body.ess_name);
            formData.append("ess_quantity", body.ess_quantity);
            formData.append("ess_ofc_number", body.ess_ofc_number);
            formData.append("ess_owned_by", body.ess_owned_by);
            formData.append("ess_image", body.ess_image[0]);

            const res = await instance.post("/crm/essential/add-essential", formData)
            return res.data
        } catch (error) {
            throw error
        }

    }
)

export const updateEssential = createAsyncThunk(
    "updateEssential",
    async (body) => {
        try {
            const res = await instance.post(`/crm/essential/update-essential?id=${body.id}`, body.data)
            return res.data
        } catch (error) {
            throw error
        }

    }
)

export const EssentialSlice = createSlice({
    name: "EssentialSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetEssentialList.pending, (state) => {
            state.loading = true;
            state.essentialResponse = null;
            state.data = null;
        });
        builder.addCase(GetEssentialList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(GetEssentialList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(CreateEssential.pending, (state) => {
            state.loading = true;
            state.essentialResponse = null;
        });
        builder.addCase(CreateEssential.fulfilled, (state, action) => {
            state.loading = false;
            state.essentialResponse = action.payload;
        });
        builder.addCase(CreateEssential.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateEssential.pending, (state) => {
            state.loading = true;
            state.essentialResponse = null;
        });
        builder.addCase(updateEssential.fulfilled, (state, action) => {
            state.loading = false;
            state.essentialResponse = action.payload;
        });
        builder.addCase(updateEssential.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
    }
})

export default EssentialSlice.reducer;
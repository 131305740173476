import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    response: null,
}

export const GetOfficeSeating = createAsyncThunk(
    "GetOfficeSeating",
    async () => {
        try {
            const response = await instance.get("/crm/office-seat/fetch-all")
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const UpdateSeat = createAsyncThunk(
    "UpdateSeat",
    async (body) => {
        try {
            const response = await instance.post(`/crm/office-seat/update-seat?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error;
        }
    }
)

export const OfficeSeatSlice = createSlice({
    name: "OfficeSeatSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetOfficeSeating.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.response = null;
        });
        builder.addCase(GetOfficeSeating.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(GetOfficeSeating.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(UpdateSeat.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(UpdateSeat.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(UpdateSeat.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

    }
})

export default OfficeSeatSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"
import axios from "axios";

const initialState = {
    data: null,
    loading: false,
    error: null,
    // singleBuilder: null,
    respStatus: null,
}

export const fetchBuilder = createAsyncThunk(
    "fetchBuilder",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/builder/fetch-all-builder?search=${body.search}&page=${body.page}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const insertBuilder = createAsyncThunk(
    "insertBuilder",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post('/fraction/builder/insert-builder', body);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const updateBuilder = createAsyncThunk(
    "updateBuilder",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post(`/fraction/builder/update-builder?id=${body.id}`, body.data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const BuilderSlice = createSlice({
    name: 'BuilderSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBuilder.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(fetchBuilder.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchBuilder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(insertBuilder.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(insertBuilder.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(insertBuilder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateBuilder.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(updateBuilder.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(updateBuilder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
    },
})

export default BuilderSlice.reducer;
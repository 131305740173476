import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    error: null,
    singlePurPro: null,
}


export const fetchPurchasedProperties = createAsyncThunk(
    "fetchPurchasedProperties",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/purchase-property/fetch-all-purchased-property?page=${body.page}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const fetchSinglePurchasedProperty = createAsyncThunk(
    "fetchSinglePurchasedProperty",
    async (id, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/purchase-property/fetch-single-purchased-property?id=${id}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const PurchasedPropertySlice = createSlice({
    name: 'PurchasedPropertySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPurchasedProperties.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchPurchasedProperties.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchPurchasedProperties.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(fetchSinglePurchasedProperty.pending, (state, action) => {
            state.loading = true;
            state.singlePurPro = null;
        })
        builder.addCase(fetchSinglePurchasedProperty.fulfilled, (state, action) => {
            state.loading = false;
            state.singlePurPro = action.payload;
        })
        builder.addCase(fetchSinglePurchasedProperty.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

    },

})

export default PurchasedPropertySlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    response: null,
    singleIntern : null,
}

export const getInternList = createAsyncThunk(
    "getInternList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/internship/fetch-intern-list?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const updateInternship = createAsyncThunk(
    "updateInternship",
    async (body) => {
        try {
            const response = await instance.post(`/remark/internship/update-intern?id=${body.id}`,body.data)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const singleInternship = createAsyncThunk(
    "singleInternship",
    async (body) => {
        try {
            const response = await instance.get(`/remark/internship/fetch-single-intern?slug=${body}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const InternshipSlice = createSlice({
    name: "InternshipSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInternList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getInternList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getInternList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
      
        builder.addCase(updateInternship.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(updateInternship.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.response = action.payload;
        });
        builder.addCase(updateInternship.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(singleInternship.pending, (state, action) => {
            state.loading = true;
            state.singleIntern = null;
        });
        builder.addCase(singleInternship.fulfilled, (state, action) => {
            state.loading = false;
            state.singleIntern = action.payload;
        });
        builder.addCase(singleInternship.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    
    }
})

export default InternshipSlice.reducer;
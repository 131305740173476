import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"


const initialState = {
    data: null,
    loading: false,
    error: null,
    galleryResponse: null,
}

export const GetGalleryList = createAsyncThunk(
    "GetGalleryList",
    async (body) => {
        try {
            const response = await instance.get(`crm/gallery/fetch-photos?folderFilter=${body.folderFilter}`)
            return response.data;
        } catch (error) {
            return error?.response
        }
    }
)

export const CreateGallery = createAsyncThunk(
    "CreateGallery",
    async (body) => {
        try {
            const formData = new FormData();
            formData.append("g_title", body.g_title);
            formData.append("g_image", body.g_image[0]);
            formData.append("g_folder", body.g_folder);
            const res = await instance.post("/crm/gallery/add-image", formData)
            return res.data
        } catch (error) {
            throw error
        }
    }
)



export const GallerySlice = createSlice({
    name: "GallerySlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetGalleryList.pending, (state) => {
            state.loading = true;
            state.galleryResponse = null;
        });
        builder.addCase(GetGalleryList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(GetGalleryList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(CreateGallery.pending, (state) => {
            state.loading = true;
            state.galleryResponse = null;
        });
        builder.addCase(CreateGallery.fulfilled, (state, action) => {
            state.loading = false;
            state.galleryResponse = action.payload;
        });
        builder.addCase(CreateGallery.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });
    }
})

export default GallerySlice.reducer;
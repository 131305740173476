import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    response: null,
}

export const getQuizRoomList = createAsyncThunk(
    "getQuizRoomList",
    async (body) => {
        try {
            const response = await instance.get(`/remark/quiz/fetch-quiz-room?page=${body.page}&search=${body.search}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)



export const QuizSlice = createSlice({
    name: "QuizSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getQuizRoomList.pending, (state, action) => {
            state.loading = true;
            state.response = null;
        });
        builder.addCase(getQuizRoomList.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getQuizRoomList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    
    }
})

export default QuizSlice.reducer;
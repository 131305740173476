import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    error: null,
    response: null,
}

export const fetchAllLlp = createAsyncThunk(
    "fetchAllLlp",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.get(`/fraction/llp/fetch-all-llp?search=${body.search}&page=${body.page}`);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

// export const fetchSingleClient = createAsyncThunk(
//     "fetchSingleClient",
//     async (id, { rejectWithValue }) => {
//         try {
//             const response = await instance.get(`/fraction/client/fetch-single-client?id=${id}`);
//             return response.data;
//         }
//         catch (error) {
//             return rejectWithValue(error?.response)
//         }
//     }
// )
// export const updateClientKyc = createAsyncThunk(
//     "updateClientKyc",
//     async (body, { rejectWithValue }) => {
//         try {
//             const response = await instance.post(`/fraction/client/update-kyc?id=${body.id}`,body.data);
//             return response.data;
//         }
//         catch (error) {
//             return rejectWithValue(error?.response)
//         }
//     }
// )

export const createLlp = createAsyncThunk(
    "createLlp",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post('/fraction/llp/create-llp',body);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const updateLlp = createAsyncThunk(
    "updateLlp",
    async (body, { rejectWithValue }) => {
        try {
            const response = await instance.post(`/fraction/llp/update-llp?id=${body.id}`, body.data);
            return response.data;
        }
        catch (error) {
            return rejectWithValue(error?.response)
        }
    }
)

export const LlpFractionSlice = createSlice({
    name: 'LlpFractionSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllLlp.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(fetchAllLlp.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        builder.addCase(fetchAllLlp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        // builder.addCase(fetchSingleClient.pending, (state, action) => {
        //     state.loading = true;
        //     state.singleCli = null;
        //     state.respStatus = null;
        // })
        // builder.addCase(fetchSingleClient.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.singleCli = action.payload;
        // })
        // builder.addCase(fetchSingleClient.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload?.data;
        // });

        builder.addCase(createLlp.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(createLlp.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(createLlp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        builder.addCase(updateLlp.pending, (state, action) => {
            state.loading = true;
            state.respStatus = null;
        })
        builder.addCase(updateLlp.fulfilled, (state, action) => {
            state.loading = false;
            state.respStatus = action.payload;
        })
        builder.addCase(updateLlp.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data;
        });

        // builder.addCase(updateClientKyc.pending, (state, action) => {
        //     state.loading = true;
        //     state.respStatus = null;
        // })
        // builder.addCase(updateClientKyc.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.respStatus = action.payload;
        // })
        // builder.addCase(updateClientKyc.rejected, (state, action) => {
        //     state.loading = false;
        //     state.error = action.payload?.data;
        // });

    },

})

export default LlpFractionSlice.reducer;
import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./crmSlices/authSlice/AuthSlice";
import DashboardSlice from "./crmSlices/dashboardSlice/DashboardSlice";
import EmployeeSlice from "./crmSlices/employeeSlice/EmployeeSlice";
import AttendanceSlice from "./crmSlices/attendanceSlice/AttendanceSlice";
import TaskSlice from "./crmSlices/taskSlice/TaskSlice";
import LeadSlice from "./crmSlices/leadSlice/LeadSlice";
import EventSlice from "./crmSlices/eventSlice/EventSlice";
import ExpenseSlice from "./crmSlices/expenseSlice/ExpenseSlice";
import UserSlice from "./remarkSlice/userSlice/UserSlice";
import WorkUpdateSlice from "./crmSlices/workUpdateSlice/WorkUpdateSlice";
import RemarkDashboardSlice from "./remarkSlice/remarkDashboardSlice/RemarkDashboardSlice";
import OfficeSeatSlice from "./crmSlices/officeSeatSlice/OfficeSeatSlice";
import CompanyKycSlice from "./remarkSlice/companyKyc/CompanyKycSlice";
import InquirySlice from "./remarkSlice/inquiry/InquirySlice";
import JobSlice from "./remarkSlice/jobSlice/JobSlice";
import EmployerSlice from "./remarkSlice/employer/EmployerSlice";
import CompanySlice from "./remarkSlice/company/CompanySlice";
import CandidateSlice from "./remarkSlice/candidate/CandidateSlice";
import PropertySlice from "./fractionSlice/property/PropertySlice";
import TicketSlice from "./crmSlices/ticketSlice/TicketSlice";
import ClientSlice from "./fractionSlice/client/ClientSlice";
import PurchasedPropertySlice from "./fractionSlice/purchaseProperty/PurchasePropertySlice";
import RoleSlice from "./crmSlices/roleSlice/RoleSlice";
import RecentActivitySlice from "./crmSlices/recentActivitySlice/RecentActivitySlice";
import InterviewSlice from "./crmSlices/interviewSlice/InterviewSlice";
import MarketingSlice from "./crmSlices/marketingSlice/MarketingSlice";
import GallerySlice from "./crmSlices/gallerySlice/GallerySlice";
import InternshipSlice from "./remarkSlice/internship/InternshipSlice";
import QuizSlice from "./remarkSlice/quiz/QuizSlice";
import ShortlistSlice from "./remarkSlice/shortlist/ShortlistSlice";
import InquiryFractionSlice from "./fractionSlice/inquiry/InquiryFractionSlice";
import RequestSlice from "./fractionSlice/request/RequestSlice";
import AnalyticFracSlice from "./fractionSlice/dashboard/AnalyticFracSlice";
import LlpFractionSlice from "./fractionSlice/llp/LlpFractionSlice";
import NotificationSlice from "./crmSlices/notificationSlice/NotificationSlice";
import EssentialSlice from "./crmSlices/essentialSlice/EssentialSlice";
import BuilderSlice from "./fractionSlice/builder/BuilderSlice";

const rootReducer = combineReducers({
    
    // Visko CRM Slice
    AuthSlice,
    DashboardSlice,
    EmployeeSlice,
    AttendanceSlice,
    TaskSlice,
    LeadSlice,
    EventSlice,
    ExpenseSlice,
    WorkUpdateSlice,
    OfficeSeatSlice,
    TicketSlice,
    RoleSlice,
    RecentActivitySlice,
    GallerySlice,
    InterviewSlice,
    MarketingSlice,
    NotificationSlice,
    EssentialSlice,

    
    // Remark Slices
    UserSlice,
    RemarkDashboardSlice,
    CompanyKycSlice,
    InquirySlice,
    JobSlice,
    EmployerSlice,
    CompanySlice,
    CandidateSlice,
    InternshipSlice,
    QuizSlice,
    ShortlistSlice,


    // Fraction Slice
    AnalyticFracSlice,
    PropertySlice,
    ClientSlice,
    PurchasedPropertySlice, 
    InquiryFractionSlice,
    RequestSlice,
    LlpFractionSlice,
    BuilderSlice,

})

export default rootReducer; 
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../../config/Config";

const initialState = {
    loading: false,
    data: null,
    error: null,
    sending: null,
}

export const GetMessages = createAsyncThunk(
    "GetMessages",
    async (body) => {
        try {
            const response = await instance.get(`/crm/work-update/fetch-messages?e_id=${body.e_id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    })



export const CreateMessage = createAsyncThunk(
    "CreateMessage",
    async (body, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append("wu_message", body.wu_message);
        formData.append("wu_title", body.wu_title);
        try {
            const response = await instance.post("/crm/work-update/create-message", formData);
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)


export const WorkUpdateSlice = createSlice({
    name: "WorkUpdateSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetMessages.pending, (state) => {
            state.loading = true;
            state.sending = null;
        });
        builder.addCase(GetMessages.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(GetMessages.rejected, (state, action) => {
            state.loading = true;
            state.error = action.payload;
        });

        builder.addCase(CreateMessage.pending, (state) => {
            state.loading = true;
            state.sending = null;
        });
        builder.addCase(CreateMessage.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.sending = action.payload;
        });
        builder.addCase(CreateMessage.rejected, (state, action) => {
            state.loading = true;
            state.sending = null;
            state.error = action.payload.data;
        })

    }

})

export default WorkUpdateSlice.reducer;
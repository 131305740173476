import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import instance from "../../../config/Config"

const initialState = {
    data: null,
    loading: false,
    status: null,
    filterData: null,
}

export const getAnalytics = createAsyncThunk(
    "getAnalytics",
    async (body) => {
        try {
            const response = await instance.get("/remark/dashboard/analytics")
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const FilteredAnalyticsByDate = createAsyncThunk(
    "FilteredAnalyticsByDate",
    async (body) => {
        try {
            const response = await instance.post("/remark/dashboard/filtered-analytics",body)
            return response.data;
        } catch (error) {
            throw error
        }
    }
)

export const RemarkDashboardSlice = createSlice({
    name: "RemarkDashboardSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAnalytics.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getAnalytics.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.data = action.payload;
        });
        builder.addCase(getAnalytics.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })

        builder.addCase(FilteredAnalyticsByDate.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(FilteredAnalyticsByDate.fulfilled, (state, action) => {
            state.loading = false;
            state.status = action.payload.status;
            state.filterData = action.payload;
        });
        builder.addCase(FilteredAnalyticsByDate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    
    }
})

export default RemarkDashboardSlice.reducer;